//MENU HAMBURGER
const btn = document.querySelector(".menu__btn");
const menu = document.querySelector(".menu");

btn.addEventListener("click", (e) => {
    menu.classList.toggle("menu--visible");
    btn.classList.toggle("menu__btn--open");
});

const li = document.querySelectorAll('.nav__el');
li.forEach((element) => {
	element.addEventListener('click', function() {
		menu.classList.remove("menu--visible");
		btn.classList.remove("menu__btn--open");
	});
});

//TRANSITION DE PAGE
const link = document.querySelectorAll(".btn__anim");
const page = document.querySelector("#anim");

link.forEach((element) =>{
	element.addEventListener("click", function(e){

		//récupérer le href, stocker
		const currentLink = element.href;

		//ajouter class .animation au body
		page.classList.add("animation");

		//animation end du body
		page.addEventListener("animationend", function(){

			//active le lien (changement de page)
			window.location = currentLink;
			
		});

		e.preventDefault(); //un lien qui ne lien plus


	});
});

//COOKIES
//BY http://christophersaenen.be/
var cookie = localStorage.getItem('cookies');

if(!(cookie == 'true')){
    document.querySelector('.cookies').classList.add('displayCookies');    

    setTimeout(function(){
        document.querySelector('.cookies').classList.add('showCookies');    
    }, 150);
}

document.querySelector('.cookies__btn--accept').addEventListener('click', function(){
    localStorage.setItem('cookies', 'true');
        document.querySelector('.cookies').classList.remove('showCookies');    

    setTimeout(function(){
        document.querySelector('.cookies').classList.remove('displayCookies');    
    }, 1500);
});

document.querySelector('.cookies__btn--refuse').addEventListener('click', function(){
    localStorage.setItem('cookies', 'true');
        document.querySelector('.cookies').classList.remove('showCookies');    

    setTimeout(function(){
        document.querySelector('.cookies').classList.remove('displayCookies');    
    }, 1500);
});